<template>
  <div id="profile">
    <mainMenu></mainMenu>
    <div class="headerPreview">
      <div class="wrapper">
        <div class="sectionInfo">
          <h1 class="sectionTitle">
            <icon name="user" scale="2.4" class="mr-50"></icon>
            Профиль пользователя
          </h1>
          <h2 class="sectionDescription">Личная страница</h2>
        </div>
      </div>
    </div>
    <div class="profileInfo">
      <div class="wrapper">
        <form action="" class="userData">
          <div class="form-block">
            <label for="">Имя пользователя</label>
            <span class="form-input">
              {{ profileData.displayName }}
            </span>
          </div>
          <div class="form-block">
            <label for="">Электроный адрес</label>
            <input type="text" v-model="profileData.email" class="form-input" />
          </div>
          <div class="form-block">
            <label for="">Статус пользователя</label>
            <span class="form-input">
              {{ profileData.status == "simple" ? "Стандартный пользователь" : "Премиум пользователь" }}
            </span>
          </div>
          <div class="form-block" v-if="profileData.status !== 'simple'">
            <label for="">Статус премиум истекает</label>
            <span class="form-input">
              {{ profileData.premiumExpiration | locale }}
            </span>
          </div>
          <div class="form-button-block">
            <button type="button" class="btn btn-changePassword" @click="isResetPassword = true">Сменить пароль</button>
            <button type="button" class="btn btn-save">Сохранить</button>
          </div>
          <!-- Placeholder for input -->
          <div
            class="acceptLicense"
            v-if="!paymentLoading && profileData.status == 'simple' && !profileData.statusCheck"
          >
            <input type="checkbox" id="acceptLicense" name="acceptLicense" v-model="isAcceptLicense.isAccept" />
            <label for="acceptLicense">
              Я ознакомлен с условиями
              <router-link :to="{ name: 'publicOffer' }">публичной оферты</router-link> и
              <router-link :to="{ name: 'contract' }">договора</router-link>
            </label>
            <p class="acceptLicenseNotify" v-if="isAcceptLicense.isError">
              Требуется подтверждение ознакомления с усовиями публичной оферты и договором
            </p>
          </div>
        </form>
        <p v-if="!paymentLoading && profileData.status == 'simple'">
          Для получения возможности скачивания всех библиотек в форматах PPTX, PSD, STL и EOFF в разделе «3D
          библиотеки», а так же использования всех 3D и VR визуализаций – Вам необходимо приобрести премиум-аккаунт.
        </p>
        <a
          href="#"
          v-if="!profileData.statusCheck"
          class="form-premiumBlock"
          :class="{ paymentLoading: paymentLoading, premium: profileData.status == 'premium' }"
          v-on:click.prevent.stop="createPayment()"
        >
          <span v-if="!paymentLoading && profileData.status == 'simple'">
            Приобрести премиум подписку
            <br />
            300$ - 12 месяцев
          </span>
          <span v-if="!paymentLoading && profileData.status == 'premium'">
            <p>Вы премиум пользователь!</p>
            <br />
            <small>Вы хотите продлить подписку?</small>
          </span>
          <span v-if="paymentLoading">
            Загрузка реквизитов...
          </span>
        </a>
        <a href="#" v-if="profileData.statusCheck" class="form-premiumBlock paymentLoading"
          >Проверка статуса платежа...</a
        >
        <p v-if="!paymentLoading && profileData.status == 'simple'">
          ВНИМАНИЕ! Перед совершением оплаты, рекомендуем убедиться в следующем:<br />
          1. Открыта ли Ваша банковская карта для расчетов в интернете?<br />
          2. Достаточно ли на Вашей банковской карте денежных средств, для совершения покупки на указанную ниже
          сумму?<br />
          3. Не превышает ли указанная ниже сумма оплаты установленный на Вашей банковской карте интернет-лимит для
          расчетов в интернете?<br />
          4. Не запретил ли обслуживающий банк расчеты по Вашей банковской карте?<br />
          5. Не запрещает ли национальное законодательство страны Вашего пребывания совершать подобные расчеты в
          интернете?<br />
          При возникновении каких либо проблем с выполнением оплаты, Вы можете:<br />
          - обратиться в обслуживающий банк для выяснения причин отказа/отклонения/запрета на выполнение платежа;<br />
          - сообщить нам о невозможности оплаты, для оказания содействия в решении сложившейся ситуации, либо поиска
          альтернативных способов оплаты.
        </p>
        <savedVisualizations></savedVisualizations>
      </div>
    </div>
    <resetPasswordModal
      v-if="isResetPassword"
      @close="isResetPassword = false"
      :resetId="profileData.id"
      :resetToken="profileData.token"
    ></resetPasswordModal>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import savedVisualizations from "./../savedVisualizations/savedVisualizations.vue";
import resetPasswordModal from "./../modals/resetPasswordModal.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";
import moment from "moment";

export default {
  name: "Profile",
  components: { mainMenu, savedVisualizations, resetPasswordModal, mainFooter },
  data() {
    return {
      endpoint: "profile/",
      paymentEndpoint: "payment/create",
      paymentLoading: false,
      isResetPassword: false,
      isAcceptLicense: {
        isAccept: false,
        isError: false,
      },
      profileData: {
        id: "",
        token: "",
        displayName: "",
        email: "",
        status: "",
        statusCheck: false,
        premiumExpiration: new Date(),
      },
    };
  },
  methods: {
    loadProfileData() {
      this.$http.get(this.endpoint).then((response) => {
        if (response.data.profile) {
          // console.log(response.data.profile)
          this.profileData.id = response.data.profile._id;
          this.profileData.token = response.data.profile.auth.local.verification_token;
          this.profileData.displayName = response.data.profile.displayName;
          this.profileData.email = response.data.profile.email;
          this.profileData.status = response.data.profile.status;
          this.profileData.statusCheck = response.data.profile.statusCheck;
          this.profileData.premiumExpiration = response.data.profile.premiumExpiration;
        } else {
          console.log(response.data.err);
        }
      });
    },
    createPayment() {
      if (this.isAcceptLicense.isAccept) {
        this.paymentLoading = true;
        this.$http.post(this.paymentEndpoint).then((response) => {
          this.paymentLoading = false;
          if (response.data.success) {
            this.$store.dispatch("signout");
            window.open(response.data.url, "_self");
          } else {
            console.log(response);
          }
        });
      } else {
        this.isAcceptLicense.isError = true;
      }
    },
  },
  filters: {
    locale(item) {
      return moment(item).locale("ru").format("DD.MM.YYYY");
    },
  },
  created() {
    this.loadProfileData();
  },
};
</script>

<style lang="sass">
#profile
	display: flex
	flex-direction: column
	min-height: calc(100vh - 200px)
	.headerPreview
		display: flex
		min-height: 200px
		align-items: center
		padding: 0px 1rem
		background: url('./../../assets/images/profileBackground.png')
		background-size: cover
		background-position: center center
		& .sectionInfo
			width: fit-content
			& .sectionTitle
				display: flex
				align-items: center
				font-size: 2.5rem
				color: #fff
				margin: 0 0 1.5rem
				font-weight: normal
			& .sectionDescription
				color: #fff
				font-size: 2rem
				font-weight: normal
	.profileInfo
		padding: 2rem 1rem
		.userData
			display: flex
			flex-direction: column
			width: 100%
			max-width: 600px
			.form-block
				display: flex
				flex-direction: column
				margin-bottom: 1rem
				label
					font-size: 1.2rem
					color: #c7c5c5
				.form-input
					display: block
					border: none
					border-bottom: 2px solid #eee
					padding: 0.5rem 0
					outline: 1px solid transparent
					font-size: 1.1rem
					color: #333
			.form-button-block
				display: flex
				flex-direction: row
				justify-content: space-between
				margin: 2rem 0 3rem
				.btn
					display: flex
					align-items: center
					justify-content: center
					max-width: 220px
					width: 100%
					height: 45px
					border: 1px solid transparent
					cursor: pointer
					&.btn-changePassword
						color: #c7c5c5
						background: transparent
						border-color: #898989
					&.btn-save
						color: #fff
						background: #0054a6
			.acceptLicense
				margin-bottom: 0.5rem
				a
					text-decoration: underline
				.acceptLicenseNotify
					margin-top: 0.5rem
					color: red
		.form-premiumBlock
			display: flex
			align-items: center
			justify-content: center
			text-align: center
			max-width: 600px
			margin: 1rem 0
			padding: 1rem 1rem
			border: 1px solid #eee
			border-radius: 8px
			font-family: 'Ropa Sans Pro SC Regular'
			font-size: 1.3rem
			color: #fff
			background: linear-gradient(135deg, #30d1c6 0%, #2ca098 50%, #30d1c6 100%)
			transition: all 0.4s
			&:hover
				background: linear-gradient(135deg, #2ca098 0%, #30d1c6 50%, #2ca098 100%)
			&.paymentLoading
				opacity: 0.7
</style>
